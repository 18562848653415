import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.1.5_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_babel-plugin-macros@3.1_75femt3a4wcbiwn3vvjq4o2w7m/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.1.5_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_babel-plugin-macros@3.1_75femt3a4wcbiwn3vvjq4o2w7m/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.1.5_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_babel-plugin-macros@3.1_75femt3a4wcbiwn3vvjq4o2w7m/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.1.5_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_babel-plugin-macros@3.1_75femt3a4wcbiwn3vvjq4o2w7m/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.1.5_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_babel-plugin-macros@3.1_75femt3a4wcbiwn3vvjq4o2w7m/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.1.5_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_babel-plugin-macros@3.1_75femt3a4wcbiwn3vvjq4o2w7m/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.1.5_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_babel-plugin-macros@3.1_75femt3a4wcbiwn3vvjq4o2w7m/node_modules/next/dist/lib/metadata/metadata-boundary.js");
