'use client';
import { useClientTeasersOnPageAtom } from '@haaretz/s-atoms/clientTeasersOnPage';
import usePage from '@haaretz/s-atoms/page';
import useTags from '@haaretz/s-atoms/tags';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import getSimplePageType from '@haaretz/s-common-utils/getSimplePageType';
import useList, { useListActions } from '@haaretz/s-use-list';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import React from 'react';

import abTestListLoader from '../../server-actions/abTestListLoader';
import lazyListLoader from '../../server-actions/lazyListLoader';

import type { AbTestListCommonProps, WithClientListProps } from '@haaretz/s-data-structure-types';
import type { ListFragment } from '@haaretz/s-fragments/List';
import type { PageType } from '@haaretz/s-types';

export type AbTestListWrapperProps = WithClientListProps<ListFragment> & {
  pageType: PageType;
} & AbTestListCommonProps;

interface AbListProps
  extends Omit<AbTestListWrapperProps, 'ListLayout'>,
    Pick<ListFragment, 'renderedArticleIds'> {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

export default function AbTestList({
  children: placeholder,
  fallback,
  ...abTestProps
}: Omit<AbListProps, 'ListLayout' | 'ListPlaceholder'>) {
  const { abTestContentId } = abTestProps;
  const { inView } = useList();
  const page = usePage();
  const [, startTransition] = React.useTransition();
  const isAbTestList = abTestProps.kind === 'abTest';
  const isLoaded = useLoadEvent();
  const [list, setList] = React.useState<React.ReactNode>(undefined);
  const [, startLazyTransition] = React.useTransition();
  const { setIsShowing } = useListActions();
  const [{ getExcludeString }, { addClientTeasers }] = useClientTeasersOnPageAtom();

  const pageType = getSimplePageType(abTestProps.pageType);

  const prevContentIdRef = React.useRef<string | undefined>();

  const { pageContentId, articleSectionId } = page || {};

  const shouldLoading =
    isAbTestList && isLoaded && inView && prevContentIdRef.current !== pageContentId;

  const tags = useTags();
  const tagIds = tags?.map(tag => tag.contentId);

  const exclude = getExcludeString(abTestProps.renderedArticleIds);

  const handleLoadLazyList = React.useCallback(
    function handleLoadMore() {
      startLazyTransition(async () => {
        try {
          const result = await lazyListLoader({
            abTestFallback: true,
            listStyle: abTestProps.listStyle,
            postCacheParams: 'sa-list-lazy',
            variables: {
              mainContentId: pageContentId,
              id: abTestProps.contentId,
              exclude,
              page: 1,
            },
          });

          if (result?.html) {
            setList(result.html);
            addClientTeasers(result.itemIds);
          } else {
            setIsShowing(false);
            console.error(new Error('Lazy list failed to load'));
          }
        } catch (error) {
          /* istanbul ignore next */
          setIsShowing(false);
          /* istanbul ignore next */
          console.error(error);
        }
      });
    },
    [
      abTestProps.listStyle,
      abTestProps.contentId,
      exclude,
      pageContentId,
      addClientTeasers,
      setIsShowing,
    ]
  );

  const handleLoadAbTestList = React.useCallback(
    function handleLoadMore() {
      startTransition(async () => {
        try {
          const result = await abTestListLoader({
            postCacheParams: 'sa-list-ab-test-personal',
            variables: discardNullableValues({
              testId: abTestContentId,
              pageType,
              exclude,
              ...(pageContentId && articleSectionId
                ? {
                    articleId: pageContentId,
                    sectionId: articleSectionId,
                    tagsIds: tagIds?.join(',') ?? '',
                  }
                : {
                    articleId: undefined,
                  }),
            }),
          });

          /* istanbul ignore next */
          if (result?.url) {
            console.table({
              title: 'AbTestList',
              style: abTestProps.listStyle,
              contentId: abTestProps.contentId,
              url: result.url,
              body: result.body,
              result: result.result,
              teaserIds: result.itemIds.toString(),
            });
          }

          if (result?.html) {
            setList(result.html);
          } else if (fallback) {
            setList(fallback);
          } else {
            handleLoadLazyList();
          }
        } catch (error) /* istanbul ignore next */ {
          console.error(error);
          /* istanbul ignore next */
          if (fallback) {
            setList(fallback);
          } else {
            handleLoadLazyList();
          }
        }
      });
    },
    [
      abTestContentId,
      abTestProps.contentId,
      abTestProps.listStyle,
      articleSectionId,
      exclude,
      fallback,
      handleLoadLazyList,
      pageContentId,
      pageType,
      tagIds,
    ]
  );

  React.useEffect(() => {
    if (shouldLoading) {
      prevContentIdRef.current = pageContentId;
      handleLoadAbTestList();
    }
  }, [handleLoadAbTestList, shouldLoading, pageContentId]);

  if (list) {
    return list;
  }

  return <>{placeholder}</>;
}
