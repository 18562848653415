type UrlProps =
  | 'href'
  | 'origin'
  | 'protocol'
  | 'username'
  | 'password'
  | 'hostname'
  | 'port'
  | 'pathname'
  | 'search'
  | 'hash'
  | 'host'
  | 'searchParams'
  | 'toString';

export type UrlParts = Pick<URL, UrlProps>;

const relativeUrlRegex =
  /^((?:(ws|https?)(?::\/\/))?(?:(\w.*):(.+?)@)?(([^\s?#/]+?)\.([^\s?#/]+?))?(?::(\d{2,5}))?)?(\/[^\s?#]*?)?(?:\/?(\?[^\s#]+?))?(#.+)?$/;

function breakRelativeUrl(url: string): UrlParts {
  //  [0]  - href. Ex. "http://www.haaretz.co.il:3000/graphql?query#bang"
  //  [1]  - origin (protocol + hostname + port). Ex. "http://www.haaretz.co.il:3000"
  //  [2]  - protocol. Ex. "http" , "https" , "ws" , "www" , "promotions"
  //  [3]  - username
  //  [4]  - password
  //  [5]  - hostname. Ex. "www.haaretz.co.il"
  //  [6]  - subdomain. Ex. "elia" , "www" , "promotions"
  //  [7]  - domain (root). Ex. "haaretz.co.il" , "themarker.com"
  //  [8]  - port (optional) - Ex: 3000, 2004
  /* eslint-disable-next-line max-len */
  //  [9]  - pathname (optional) - Ex: /graphql, /papi/.premium-1204 (forward slash sign is included in result)
  //  [10] - search (optional) - Ex: ?key1=value1&key2=value3&key3 (questionmark sign is ommited in result)
  //  [11] - hash (optional) - Ex: #article-comments (hash sign is ommited in result)

  const [
    /* istanbul ignore next */
    href = '',
    origin = '',
    protocol = '',
    username = '',
    password = '',
    hostname = '', // subdomain = '' // domain = '',
    ,
    ,
    port = '',
    pathname = '',
    search = '',
    hash = '',
  ] = relativeUrlRegex.exec(url) || /* istanbul ignore next */ [];

  /* istanbul ignore next */
  const host = hostname + port ? `:${port}` : '';
  const searchParams = new URLSearchParams(search);

  return {
    href,
    origin,
    protocol,
    username,
    password,
    hostname,
    port,
    pathname,
    search,
    hash,
    host,
    searchParams,
    toString: url.toString,
  };
}

/**
 * Return parts of URL
 *
 * @public
 */
export default function breakUrl(url: NonNullable<string | URL>): UrlParts {
  const urlIsString = typeof url === 'string';
  const isAbsoluteUrl = !urlIsString || url.indexOf('://') > 0 || url.indexOf('//') === 0;
  if (isAbsoluteUrl) {
    try {
      const {
        href,
        origin,
        protocol,
        username,
        password,
        hostname,
        port,
        pathname,
        search,
        hash,
        host,
        searchParams,
        toString,
      } = new URL(url);

      return {
        href,
        origin,
        protocol,
        username,
        password,
        hostname,
        port,
        pathname,
        search,
        hash,
        host,
        searchParams,
        toString,
      };
    } catch (error) {
      console.error(`Failed to parse URL: ${url}`);
      return breakRelativeUrl(urlIsString ? url : url.toString());
    }
  }

  return breakRelativeUrl(url);
}
