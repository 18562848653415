import config from '@haaretz/l-config';

import type { HtzAppConfig } from '@haaretz/l-app-config/types';
import type { ConnectionType, Env, PlatformType } from '@haaretz/s-types';
import type { LinkProps as NextLinkProps } from 'next/link';
export type Url = NextLinkProps['href'];

export const supportedPageTypes: HtzAppConfig<Env, ConnectionType>['supportedNextUrl'] =
  config.get('supportedNextUrl');

const extraNextLinks = ['?liveBlogItemId='] as const;
const appSpecialLinks = ['_app=true'] as const;

export default function isNextLink(href: Url, platform: PlatformType): boolean {
  const stringHref = (typeof href === 'string' ? href : href.href)?.toLowerCase();
  // 1. External URL are always non-Next.Js
  if (!stringHref || stringHref.startsWith('http')) return false;

  if (extraNextLinks.some(extraLink => stringHref.includes(extraLink.toLowerCase()))) return true;
  if (
    platform === 'app' &&
    appSpecialLinks.some(extraLink => stringHref.includes(extraLink.toLowerCase()))
  ) {
    return false;
  }

  return supportedPageTypes.some(supportedPage =>
    supportedPage === '/' ? stringHref === '/' : stringHref.includes(supportedPage.toLowerCase())
  );
}
