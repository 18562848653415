'use client';

import usePreviewId from '@haaretz/s-atoms/previewId';
import * as React from 'react';

import useSlotData from '../RainbowDataProvider/data/DataProvider';
import useSlotsRegistration from '../RainbowDataProvider/slots/SlotsProvider';
import RainbowTool from '../RainbowTool';

import RainbowSlotProvider, { useRainbowSlotActions } from './RainbowSlotProvider';

import type { RainbowToolFragment } from '@haaretz/s-fragments/RainbowTool';
import type { StyleExtend } from '@haaretz/s-types';

export interface RainbowSlotProps {
  /** Id of the slot */
  id: string;
  /** event-handler to fire when slot renders a rainbow-tool */
  onToolRendered?: (tool: RainbowToolFragment) => void;
  /** extra styles to pass to the rendered rainbow element */
  styleExtend?: StyleExtend;

  opened?: boolean;
}

function RainbowSlot({ id, styleExtend, onToolRendered }: RainbowSlotProps) {
  const { registerSlot, unregisterSlot } = useSlotsRegistration();
  const previewId = usePreviewId();
  const tool = useSlotData(id);

  const { opened } = useRainbowSlotActions();

  React.useEffect(() => {
    registerSlot(id);

    return () => {
      unregisterSlot(id);
    };
  }, [id, registerSlot, unregisterSlot]);

  return opened && !previewId && tool ? (
    <RainbowTool
      key={tool.toolId}
      tool={tool}
      styleExtend={styleExtend}
      onToolRendered={onToolRendered}
    />
  ) : null;
}

export default function RainbowSlotWrapper(props: RainbowSlotProps) {
  return (
    <RainbowSlotProvider>
      <RainbowSlot {...props} />
    </RainbowSlotProvider>
  );
}
