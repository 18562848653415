import config from '@haaretz/l-config';

const GAM_CAMPAIGNS_SERVICE_URL = `${config.get(
  'personalization'
)}/marketing/get-user-gam-campaigns`;

export default function requestGamCampaignIds() {
  return fetch(GAM_CAMPAIGNS_SERVICE_URL, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then(resp => resp.json());
}
