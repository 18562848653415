'use client';

import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import Button from '@haaretz/s-button';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import React from 'react';
import s9 from 'style9';

import type { BiData } from '@haaretz/s-data-structure-types';
import type { BiFunction } from '@haaretz/s-use-bi';

interface GiftBtnProps {
  onClick?: () => void;
}

const c = s9.create({
  giftBtn: {
    width: 'fit-content',
    paddingInlineEnd: space(2),
    paddingInlineStart: space(2),
  },
});

const biData: BiData = {
  campaign_name: 'HTZ gift subscription button',
  campaign_details: 'hello_default',
  feature: 'Hello Subscription',
  feature_type: 'Marketing',
  action_id: 49,
};

const onClickGiftBtn = (biAction: BiFunction) => {
  biAction(biData);
};

export default function GiftBtn({ onClick }: GiftBtnProps) {
  const buttonRef = React.useRef<HTMLAnchorElement | null>(null);
  const biAction = useBi();
  const user = useUser('cookieValue');
  const platform = usePlatform();
  const buttonPriority = platform === 'mobile' ? 'primary' : 'tertiary';

  useImpressionObserver({
    elementRef: buttonRef,
    biData,
  });

  return fork({
    default: null,
    htz:
      user.userType === 'paying' ? (
        <Button
          href="https://promotion.haaretz.co.il/giftSubscription"
          as={HtzLink}
          variant="sales"
          priority={buttonPriority}
          styleExtend={[c.giftBtn]}
          onClick={() => {
            onClickGiftBtn(biAction);
            typeof onClick === 'function' && onClick();
          }}
          ref={buttonRef}
          startIcon="gift"
          size="small"
        >
          <span>תנו מינוי במתנה</span>
        </Button>
      ) : null,
  });
}
