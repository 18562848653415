'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import React from 'react';
import s9 from 'style9';

import { useAudioPlayer } from '../hooks/useAudioPlayer';
import { ffLabel, playLabel, rwLabel, skipTime } from '../utils/audioPlayerUtils';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';

const c = s9.create({
  skipButton: {
    fontSize: space(6),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          fontSize: space(7),
        },
      })
    ),
  },
  skipLabel: {
    userSelect: 'none',
    pointerEvents: 'none',

    position: 'absolute',
    top: '30%',
    fontWeight: 700,
    ...typesetter(-4),
    ...merge(
      mq({
        from: 'xl',
        value: {
          ...typesetter(-6),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          top: '32%',
        },
      })
    ),
  },
  rw: {
    // The control buttons are ordered left-to-right in both RTL and LTR, so we need to manually handle this
    gridColumnStart: fork({ default: '3', hdc: '2' }),
    gridColumnEnd: fork({ default: '4', hdc: '3' }),
    gridRowStart: '3',
    gridRowEnd: '4',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    ...merge(
      mq({
        from: 's',
        value: {
          alignItems: 'center',
          // The control buttons are ordered left-to-right in both RTL and LTR, so we need to manually handle this
          gridColumnStart: fork({ default: '-1', hdc: '-3' }),
          gridColumnEnd: fork({ default: '-2', hdc: '-4' }),
          gridRowStart: '2',
          gridRowEnd: '3',
        },
      })
    ),
  },
  ff: {
    // The control buttons are ordered left-to-right in both RTL and LTR, so we need to manually handle this
    gridColumnStart: fork({ default: '2', hdc: '3' }),
    gridColumnEnd: fork({ default: '3', hdc: '4' }),
    gridRowStart: '3',
    gridRowEnd: '4',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    ...merge(
      mq({
        from: 's',
        value: {
          alignItems: 'center',
          // The control buttons are ordered left-to-right in both RTL and LTR, so we need to manually handle this
          gridColumnStart: fork({ default: '-3', hdc: '-1' }),
          gridColumnEnd: fork({ default: '-4', hdc: '-2' }),
          gridRowStart: '2',
          gridRowEnd: '3',
        },
      })
    ),
  },
  paff: {
    gridColumnStart: fork({ default: 1, hdc: 3 }),
    gridColumnEnd: fork({ default: 2, hdc: 4 }),
    gridRowStart: 1,
    gridRowEnd: -1,
    alignSelf: 'center',
    justifySelf: 'end',
  },
  audioWrap: {
    gridColumnStart: '4',
    gridColumnEnd: '5',
    gridRowStart: '2',
    gridRowEnd: '4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...merge(
      mq({
        from: 's',
        value: {
          gridColumnStart: '-2',
          gridColumnEnd: '-3',
          gridRowStart: '2',
          gridRowEnd: '3',
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          margin: `0 ${space(-1)}`,
        },
      })
    ),
  },

  paAudioWrap: {
    gridColumnStart: fork({ default: 2, hdc: 4 }),
    gridColumnEnd: fork({ default: 3, hdc: 4 }),
    gridRowStart: 1,
    gridRowEnd: -1,
  },
  parw: {
    justifySelf: 'start',
    alignSelf: 'center',
    gridColumnStart: fork({ default: 3, hdc: 4 }),
    gridColumnEnd: fork({ default: 4, hdc: 4 }),
    gridRowStart: 1,
    gridRowEnd: -1,
  },
  audioButtonWrapperSchoonhoven: {
    ...merge(
      mq({
        from: 'l',
        until: 'xxl',
        value: { margin: `0 ${space(-3)}` },
      }),
      mq({
        from: 'xxl',
        value: { margin: `0 ${space(-2)}` },
      })
    ),
  },
  audioButton: {
    fontSize: space(16),
    width: '1em',
    height: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: radius('circle'),
    backgroundColor: color('primary1000'),
    ...merge(
      mq({
        from: 's',
        value: {
          fontSize: space(12),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          fontSize: space(14),
        },
      })
    ),
  },
  audioButtonSmall: {
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          fontSize: space(11),
        },
      }),
      mq({
        from: 'l',
        until: 'xxl',
        value: {
          fontSize: space(13),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          fontSize: space(14),
        },
      })
    ),
  },
  audioButtonPa: {
    backgroundColor: color('neutral1200'),
    width: space(15),
    height: space(15),
    ...merge(
      mq({ from: 's', until: 'l', value: { width: space(20), height: space(20) } }),
      mq({ from: 'l', until: 'xl', value: { width: space(12), height: space(12) } }),
      mq({ from: 'xl', until: 'xxl', value: { width: space(16), height: space(16) } }),
      mq({ from: 'xxl', value: { width: space(17), height: space(17) } })
    ),
  },
  icon: {
    fontSize: space(8),
    ...merge(
      mq({
        from: 's',
        value: {
          fontSize: space(6),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          fontSize: space(7),
        },
      })
    ),
  },
  iconPa: {
    fontSize: space(9.25),
    ...merge(
      mq({ from: 's', until: 'l', value: { fontSize: space(10) } }),
      mq({ from: 'xl', until: 'xxl', value: { fontSize: space(8) } }),
      mq({ from: 'xxl', value: { fontSize: space(8) } })
    ),
  },
  skipButtonPA: {
    fontSize: space(7),
    ...merge(
      mq({ from: 's', until: 'l', value: { fontSize: space(11) } }),
      mq({ from: 's', until: 'l', value: { fontSize: space(8) } }),
      mq({ from: 'xl', value: { fontSize: space(9) } }),
      mq({ from: 'xxl', value: { fontSize: space(9) } })
    ),
  },
  skipButtonSchoonhoven: {
    ...merge(
      mq({
        from: 'l',
        until: 'xxl',
        value: {
          fontSize: space(7),
        },
      })
    ),
  },
  skipLabelSchoonhoven: {
    ...merge(
      mq({
        from: 'l',
        value: {
          top: '32%',
        },
      })
    ),
  },
  skipLabelPa: {
    top: '34%',
    ...merge(
      mq({ from: 's', until: 'l', value: { ...typesetter(-2), top: '23%' } }),
      mq({ from: 'xl', until: 'xxl', value: { top: '30%', ...typesetter(-4) } }),
      mq({ from: 'xxl', value: { top: '32%', ...typesetter(-5) } })
    ),
  },
  iconPlay: {
    transform: 'translateX(10%)',
  },
  button: {
    backgroundColor: 'inherit',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
interface AudioControlsProps {
  variant?: 'default' | 'schoonhoven' | 'podcastArticle';
}

const actionId = 161;

export default function AudioControls({ variant = 'default' }: AudioControlsProps) {
  const {
    isPlaying,
    onSkip,
    onPauseButtonClick,
    onPlayButtonClick,
    onPlayingCallback,
    onPauseCallback,
    channelName,
    title,
    webviewLink,
  } = useAudioPlayer();

  const platform = usePlatform();
  const isListVariant = variant === 'schoonhoven';
  const isPodcastArticleVariant = variant === 'podcastArticle';
  const isPodcastArticleOnApp = isPodcastArticleVariant && platform === 'app';
  const PlayButtonElement = isPodcastArticleOnApp ? 'a' : 'button';
  const biAction = useBi();

  React.useEffect(() => {
    if (isListVariant) {
      onPlayingCallback(() => {
        if (typeof window.__HTZ.cancelRefresh === 'function') {
          window.__HTZ.cancelRefresh();
        }
      });
    }
  }, [onPlayingCallback, isListVariant]);

  React.useEffect(() => {
    if (isListVariant) {
      onPauseCallback(() => {
        if (typeof window.__HTZ.continueRefreshFn === 'function') {
          window.__HTZ.continueRefreshFn();
        }
      });
    }
  }, [onPauseCallback, isListVariant]);

  const biData: BiDataOverrides = {
    writer_name: channelName ?? '',
    action_id: actionId,
    page_type: variant === 'schoonhoven' ? 'Homepage' : 'Article',
    feature: 'podcast player',
    feature_type: 'content',
    campaign_name: `${channelName} - ${title}`,
  };

  const skipButtonAction = () => {
    biAction({
      ...biData,
      campaign_details: 'skip 15 sec forward',
    });
    onSkip(skipTime);
  };
  const rewindButtonAction = () => {
    biAction({
      ...biData,
      campaign_details: 'skip 15 sec backwardS',
    });
    onSkip(-skipTime);
  };

  const playButtonAction = () => {
    biAction({
      ...biData,
      campaign_details: 'play',
    });
    onPlayButtonClick();
  };
  const pauseButtonAction = () => {
    biAction({
      ...biData,
      campaign_details: 'pause',
    });
    onPauseButtonClick();
  };
  return (
    <>
      {!isPodcastArticleOnApp ? (
        <div className={s9(c.ff, isPodcastArticleVariant && c.paff)}>
          <button onClick={skipButtonAction} className={s9(c.button)}>
            <VisuallyHidden>{ffLabel}</VisuallyHidden>
            <Icon
              styleExtend={[
                c.skipButton,
                isListVariant && c.skipButtonSchoonhoven,
                isPodcastArticleVariant && c.skipButtonPA,
              ]}
              icon="audio-ff"
            />
            <span
              className={s9(
                c.skipLabel,
                isListVariant && c.skipLabelSchoonhoven,
                isPodcastArticleVariant && c.skipLabelPa
              )}
              aria-hidden="true"
            >
              {skipTime}
            </span>
          </button>
        </div>
      ) : null}
      <div
        className={s9(
          c.audioWrap,
          isListVariant && c.audioButtonWrapperSchoonhoven,
          isPodcastArticleVariant && c.paAudioWrap
        )}
      >
        <PlayButtonElement
          className={s9(
            c.audioButton,
            isListVariant && c.audioButtonSmall,
            isPodcastArticleVariant && c.audioButtonPa
          )}
          {...(isPodcastArticleOnApp
            ? {
                href: webviewLink ? webviewLink : undefined,
              }
            : {
                onClick: isPlaying ? pauseButtonAction : playButtonAction,
              })}
        >
          <Icon
            styleExtend={[c.icon, !isPlaying && c.iconPlay, isPodcastArticleVariant && c.iconPa]}
            icon={`${isPlaying ? 'pause' : 'play'}`}
            variant="inverse"
            screenReaderText={playLabel}
          />
        </PlayButtonElement>
      </div>
      {!isPodcastArticleOnApp ? (
        <div className={s9(c.rw, isPodcastArticleVariant && c.parw)} data-testid="rw">
          <button onClick={rewindButtonAction} className={s9(c.button)}>
            <VisuallyHidden>{rwLabel}</VisuallyHidden>
            <Icon
              styleExtend={[
                c.skipButton,
                isListVariant && c.skipButtonSchoonhoven,
                isPodcastArticleVariant && c.skipButtonPA,
              ]}
              icon="audio-rw"
            />
            <span
              className={s9(
                c.skipLabel,
                isListVariant && c.skipLabelSchoonhoven,
                isPodcastArticleVariant && c.skipLabelPa
              )}
              aria-hidden="true"
            >
              {skipTime}
            </span>
          </button>
        </div>
      ) : null}
    </>
  );
}
