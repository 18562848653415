'use client';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import useUser from '@haaretz/s-atoms/user';
import Button from '@haaretz/s-button';
import { hostname } from '@haaretz/s-consts';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import React from 'react';
import s9 from 'style9';

import getUserSubscriptionDetails from '../utils/getUserSubscriptionDetails';

import type { StyleExtend } from '@haaretz/s-types';

export interface PurchaseButtonProps {
  styleExtend?: StyleExtend;
}

const c = s9.create({
  textColor: {
    color: color('mastheadTextColor'),
  },
});

export default function PurchaseButton({ styleExtend = [] }: PurchaseButtonProps) {
  const biAction = useBi();
  const user = useUser('cookieValue');
  const { isPartialSubscriber, showPurchaseBtn } = getUserSubscriptionDetails(user);
  const purchaseButtonRef = React.useRef<HTMLAnchorElement>(null);

  const [biData, searchParams] = React.useMemo(
    () => [
      {
        feature_type: 'Marketing',
        feature: 'Hello Subscription',
        campaign_name: isPartialSubscriber ? 'upsell' : 'subscription',
        campaign_details: 'hello_default',
      },
      new URLSearchParams({
        htm_source: 'site',
        htm_medium: 'button',
        htm_campaign: 'subscription',
        htm_campaign_type: isPartialSubscriber ? 'upsell' : 'subscription',
        htm_content: 'hello_default',
      }),
    ],
    [isPartialSubscriber]
  );

  useImpressionObserver({
    biData,
    elementRef: purchaseButtonRef,
  });

  if (!showPurchaseBtn) return null;

  const onClick = () => {
    biAction({
      ...biData,
      action_id: 3,
    });
  };

  return (
    <Button
      variant="sales"
      priority="primary"
      contentStyleExtend={[c.textColor]}
      styleExtend={styleExtend}
      as={HtzLink}
      onClick={onClick}
      href={`https://promotion.${hostname}/offers?${searchParams.toString()}`}
      data-testid="purchase-btn"
      ref={purchaseButtonRef}
    >
      {fork({
        default: isPartialSubscriber ? 'שדרוג מינוי' : 'לרכישת מינוי',
        hdc: 'Subscribe',
      })}
    </Button>
  );
}
