'use client';

import { useEffect } from 'react';

interface FactsInitializerProps {
  initializer: () => Promise<void>;
}

/**
 * This component runs its effect before its parent (FactsProvider)
 */
export default function FactsInitializer({ initializer }: FactsInitializerProps) {
  useEffect(() => {
    initializer();
  }, [initializer]);

  return false;
}
