import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import HtzImage, { HtzImageProps } from '@haaretz/s-htz-image';
import CommonMasthead from '@haaretz/s-masthead/CommonMainLogo';
import React from 'react';
import s9 from 'style9';

import imagesData from '../assets/imagesData';

import BackToHomeButton from './BackToHomeButton.client';
import ErrorPageView from './ErrorPageView';
import GameLinkWrapper from './GameLinkWrapper.client';

const c = s9.create({
  layout: {
    backgroundColor: color('neutral100'),
  },
  base: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  baseHtz: {
    paddingTop: space(6),
    paddingBottom: space(8),

    ...merge(
      mq({
        from: 'l',
        value: {
          paddingTop: space(11),
          paddingBottom: space(15),
        },
      })
    ),
  },
  baseTm: {
    paddingTop: space(5),
    paddingBottom: space(12),

    ...merge(
      mq({
        from: 'l',
        value: {
          paddingTop: space(9),
          paddingBottom: space(18),
        },
      })
    ),
  },
  baseGlobalError: {
    paddingTop: space(8),
    paddingBottom: space(12),

    ...merge(
      mq({
        from: 'l',
        value: {
          paddingBottom: space(18),
        },
      })
    ),
  },
  baseTmGlobalError: {
    paddingTop: space(8),

    ...merge(
      mq({
        from: 'l',
        value: {
          paddingTop: space(13),
          paddingBottom: space(10),
        },
      })
    ),
  },
  title: {
    ...typesetter(4),
  },
  subTitle: {
    marginBottom: space(4),
    fontWeight: 400,
    ...typesetter(1),

    ...merge(
      mq({
        from: 'xl',
        value: {
          marginTop: space(1),
          ...typesetter(0),
        },
      })
    ),
  },
  img: {
    marginBottom: space(8),
    width: space(80),

    ...merge(
      mq({
        from: 'l',
        value: {
          marginBottom: space(10),
          width: space(150),
        },
      })
    ),
  },
  gamesWrapper: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: 'repeat(4, 1fr)',
    backgroundColor: color('primary200'),
    width: `calc(100vw - ${space(8)})`,
    columnGap: space(5),
    paddingTop: space(4),
    paddingBottom: space(4),
    paddingRight: space(7),
    paddingLeft: space(7),
    borderRadius: radius('xxLarge'),
    ...typesetter(-2),

    ...merge(
      mq({
        from: 'l',
        value: {
          gridTemplateColumns: 'repeat(6, 1fr)',
          columnGap: space(15),
          width: space(234),
          paddingTop: space(10),
          paddingBottom: space(10),
          paddingRight: space(15),
          paddingLeft: space(15),
          borderRadius: space(10),
        },
      })
    ),
  },
  game: {
    gridColumnStart: 'span 1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: space(1),

    ...merge(
      mq({
        from: 'l',
        value: {
          rowGap: space(2),
        },
      })
    ),
  },
  gameImg: {
    width: space(12.5),

    ...merge(
      mq({
        from: 'l',
        value: {
          width: space(21.5),
        },
      })
    ),
  },
  gamesWrapperTitle: {
    fontWeight: 400,
    textAlign: 'center',
    gridColumnStart: 1,
    gridColumnEnd: -1,
    marginBottom: space(4),

    ...merge(
      mq({
        from: 'l',
        value: {
          ...typesetter(1),
          marginBottom: space(8),
        },
      })
    ),
  },
  gameTitle: {
    ...merge(
      mq({
        from: 'l',
        value: {
          ...typesetter(-1),
        },
      })
    ),
  },
  hideUntilLarge: {
    ...merge(
      mq({
        until: 'l',
        value: {
          display: 'none',
        },
      })
    ),
  },
  hdcWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: space(20),
    paddingBottom: space(20),
  },
  hdcTitle: {
    marginBottom: space(4),
  },
  hdcBtn: {
    marginTop: space(4),
  },
  masthead: {
    marginTop: 0,
    paddingTop: space(4),
  },
});

export type ErrorPageProps = {
  variant?: 'notFound' | 'globalError' | 'gamesError';
  /** Indicates the error happend on the server and not on body */
  isServerSideError?: boolean;
};

const games = [
  {
    img: imagesData.worlde,
    title: '5 אותיות',
    url: 'https://www.haaretz.co.il/riddles/ty-page/haaretz-wordle',
    campaignDetails: 'to wordle',
  },
  {
    img: imagesData.connections,
    title: 'מה הקשר',
    url: 'https://www.haaretz.co.il/riddles/2024-03-20/ty-article-static/.premium/0000018d-9705-d57f-a1ef-bf1d5b3d0000',
    campaignDetails: 'to connections',
  },
  {
    img: imagesData.reasonable,
    title: 'הגיונית',
    url: 'https://www.haaretz.co.il/riddles/2023-12-27/ty-article-static/.premium/0000018b-7abc-d1da-a1bb-7fbcccb00000',
    campaignDetails: 'to daily phrase',
  },
  {
    img: imagesData.whoAmI,
    title: 'מי אני?',
    url: 'https://www.haaretz.co.il/riddles/2023-02-26/ty-article-static/00000184-9eae-d199-ade4-dfef359e0000',
    campaignDetails: 'to picsle',
  },
  {
    img: imagesData.whatASong,
    title: 'איזה שיר',
    url: 'https://www.haaretz.co.il/riddles/2024-08-13/ty-article-static/.free/0000018f-e796-d481-a58f-f7b6fa100000',
    campaignDetails: 'to song',
  },
  {
    img: imagesData.twentyQuestions,
    title: '20 שאלות',
    url: 'https://www.haaretz.co.il/magazine/20questions',
    campaignDetails: 'to 20 questions',
  },
];

type GameProps = {
  img: HtzImageProps;
  title: string;
  url: string;
  itemNumber: number;
  campaignDetails: string;
};

const errorData: Record<
  Exclude<ErrorPageProps['variant'], undefined>,
  {
    title: string;
    subtitle: string;
    image: HtzImageProps;
  }
> = {
  globalError: {
    title: fork({ default: 'זה לא אתם זה אנחנו' }),
    subtitle: fork({ default: 'אל דאגה, יש תקלה ואנחנו מטפלים בה' }),
    image: imagesData.globalError,
  },
  notFound: {
    title: fork({ default: 'נראה שהלכת לאיבוד' }),
    subtitle: fork({ default: 'אפשר ליהנות מהשקט או לחזור למציאות' }),
    image: imagesData.notFound,
  },
  gamesError: {
    title: fork({ default: 'אזור המשחקים עובר שיפוץ' }),
    subtitle: fork({ default: 'אל דאגה, עד הבוקר נשוב' }),
    image: imagesData.gamesError,
  },
};

function Game({ img, title, url, itemNumber, campaignDetails }: GameProps) {
  return (
    <GameLinkWrapper url={url} campaignDetails={campaignDetails}>
      <div className={s9(c.game, itemNumber >= 5 && c.hideUntilLarge)}>
        <HtzImage {...img} styleExtend={[c.gameImg]} />
        <h4 className={s9(c.gameTitle)}>{title}</h4>
      </div>
    </GameLinkWrapper>
  );
}

// TODO: remove when HDC version is ready
function Hdc() {
  return (
    <div className={s9(c.hdcWrapper)}>
      <h1 className={s9(c.hdcTitle)}>PAGE NOT FOUND</h1>
      <p>We&apos;re sorry, we seem to have lost this page.</p>
      <p>We don&apos;t want to lose you, though!</p>
      <Button styleExtend={[c.hdcBtn]} priority="secondary" as="a" href="/">
        Back to Homepage
      </Button>
    </div>
  );
}

function Default({ variant = 'notFound' }: ErrorPageProps) {
  return (
    <>
      <ErrorPageView />
      <div className={s9(c.layout)}>
        <CommonMasthead variant="searchPage" styleExtend={[c.masthead]} />
        <section
          className={s9(
            c.base,
            fork({ default: c.baseHtz, tm: c.baseTm }),
            fork({
              default: null,
              htz: variant === 'globalError' && c.baseGlobalError,
              tm: variant === 'globalError' && c.baseTmGlobalError,
            })
          )}
        >
          <HtzImage {...errorData[variant].image} styleExtend={[c.img]} envOverride="prod" />
          <h1 className={s9(c.title)}>{errorData[variant].title}</h1>
          <h2 className={s9(c.subTitle)}>{errorData[variant].subtitle}</h2>
          <BackToHomeButton variant={variant} />
          {fork({
            default: null,
            htz:
              variant === 'notFound' ? (
                <div className={s9(c.gamesWrapper)}>
                  <h3 className={s9(c.gamesWrapperTitle)}>
                    {fork({ default: 'בינתיים מעבירים את הזמן עם המשחקים של הארץ' })}
                  </h3>
                  {games.map((game, i) => (
                    <Game key={game.title} {...game} itemNumber={i + 1} />
                  ))}
                </div>
              ) : null,
          })}
        </section>
      </div>
    </>
  );
}

export default function ErrorPageTemplate({ variant, isServerSideError }: ErrorPageProps) {
  // TODO: remove when HDC version is ready
  return fork({
    default: <Default variant={variant} isServerSideError={isServerSideError} />,
    hdc: <Hdc />,
  });
}
