import color from '@haaretz/l-color.macro';
import config from '@haaretz/l-config';
import fork from '@haaretz/l-fork.macro';
import buildBaseImgSrc from '@haaretz/s-image-utils/buildBaseImgSrc';

import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';

export const playbackRate = [1, 1.25, 1.5, 1.75, 2, 0.5, 0.75];

export const skipTime = 15;

export const currentTimeLabel = fork({ default: 'זמן', hdc: 'time' });
export const durationTimeLabel = fork({ default: 'אורך', hdc: 'total' });
export const ffLabel = fork({ default: 'דילוג', hdc: 'fast forward' });
export const rwLabel = fork({ default: 'חזרה', hdc: 'rewind' });
export const playLabel = fork({ default: 'ניגון', hdc: 'play' });

export type KeyBoardButtons =
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'ArrowDown'
  | 'ArrowUp'
  | 'PageDown'
  | 'PageUp'
  | 'End'
  | 'Home';

export const keyDirection = {
  ArrowLeft: -1,
  ArrowRight: 1,
  ArrowDown: -1,
  ArrowUp: 1,
  PageDown: -3,
  PageUp: 3,
  End: Infinity,
  Home: -Infinity,
};

export const formatTime = (totalSeconds: number) => {
  if (totalSeconds < 0 || totalSeconds === Infinity) {
    return '-- : --';
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const m = hours && minutes < 10 ? `0${minutes}` : minutes;
  const s = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours ? `${hours}:` : ''}${m}:${s}`;
};

export const getProgressStyle = (
  currentTime: number,
  durationTime: number,
  variant?: 'default' | 'schoonhoven' | 'podcastArticle'
) => {
  const neutralColor = variant === 'podcastArticle' ? color('neutral600') : color('neutral400');
  return `linear-gradient(to right, ${color('primary1000')} 0%, ${color('primary1000')} ${
    (currentTime / durationTime) * 100
  }%, ${neutralColor} ${(currentTime / durationTime) * 100}%, ${neutralColor} 100%)`;
};
const Env = config.get('env');

export function getAudioLinkWebView({
  image,
  mobileTitle,
  channelLabel,
  channelLinks,
  fileUrl,
}: {
  fileUrl?: string;
  mobileTitle?: string;
  channelLabel?: string | null;
  channelLinks?: {
    google?: string | null;
    apple?: string | null;
  } | null;
  image?: ImageFragment;
}) {
  if (!fileUrl) {
    console.error("Audio Link: URL of file doesn't exist");
    return undefined;
  }

  let href = `${fileUrl}?`;

  if (mobileTitle) href += `title=${encodeURIComponent(mobileTitle)}`;
  if (channelLabel) href += `&exclusive=${encodeURIComponent(channelLabel)}`;
  if (channelLinks && channelLinks.apple) {
    href += `&register_apple=${encodeURIComponent(channelLinks.apple)}`;
  }
  if (channelLinks && channelLinks.google) {
    href += `&register_android=${encodeURIComponent(channelLinks.google)}`;
  }
  if (image) {
    const { contentId, files } = image || {};

    if (files && files[0]) {
      const imgUrl = buildBaseImgSrc({
        contentId,
        imgData: files[0],
        isCropped: true,
        env: Env,
        width: files?.[0]?.crops?.square?.width || 597,
        aspectData: {
          width: 597,
          height: 597,
          x: 0,
          y: 0,
          ...(files?.[0]?.crops?.square || {}),
        },
        aspect: 'square',
      });

      href += `&img=${encodeURIComponent(imgUrl)}`;
    }
  }

  return href;
}
