import type { HtzUser } from '@haaretz/s-types';

const subscriptionNames = {
  274: 'BOTH',
  243: 'paying_htz',
  273: 'paying_tm',
  282: 'INACTIVE',
  239: 'paying_hdc',
} as const;

export default function getUserSubscriptionDetails(user: HtzUser) {
  const userProduct = user?.products?.filter(product => {
    if (product.prodNum === 274 && product.status === 'SUBSCRIBED') {
      return true;
    }
    if (
      (product.prodNum === 243 && product.status === 'SUBSCRIBED') ||
      (product.prodNum === 273 && product.status === 'SUBSCRIBED')
    ) {
      return true;
    }
    return null;
  });

  const { prodNum, connectionType } = userProduct?.[0] || {};

  const isFromAppStores = connectionType === 763 || connectionType === 776;

  const userProductType = (prodNum && subscriptionNames[prodNum]) || user?.extendedUserType;

  const isPartialSubscriber =
    userProductType?.includes('paying_tm') || userProductType?.includes('paying_htz') || false;

  const userProductTypeLowerCased = userProductType?.toLowerCase() as Lowercase<
    typeof userProductType
  >;

  const showPurchaseBtn =
    !isFromAppStores &&
    (isPartialSubscriber ||
      userProductTypeLowerCased === 'anonymous' ||
      userProductTypeLowerCased === 'registered' ||
      userProductTypeLowerCased === 'stopped');

  return {
    isPartialSubscriber,
    showPurchaseBtn,
    userProductType: userProductTypeLowerCased,
  };
}
