import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.1.5_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_babel-plugin-macros@3.1_75femt3a4wcbiwn3vvjq4o2w7m/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/ds-atoms/s-script3rd-party/dist/Script3rdParty.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/AccessListByIp.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/ChartBeat/ChartBeatClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/CookieConsent/CookieConsentClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/GaEvents/GAConfig.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/GeoEdge/GeoEdgeClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Kilkaya/Kilkaya.client.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Outbrain/OutbrainObctClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Outbrain/OutbrainPixel/OutbrainPixelOnNavigation.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Outbrain/OutbrainPixelContent.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Permutive/PermutiveArticlePage/PermutiveArticlePageOnNavigation.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Taboola/TaboolaPixelContent.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-gtag-utils/dist/GoogleAnalytics/CustomNextGoogleAnalytics.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/utils/s-section-utils/dist/AutoRefresh/AutoRefresh.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/utils/s-section-utils/dist/CommonUtils/CommonUtils.js");
