export default function mergeURLSearchParams(
  initialParams?: URLSearchParams,
  additionalParams?: URLSearchParams
): URLSearchParams {
  const merged = new URLSearchParams(initialParams);

  if (additionalParams) {
    for (const [key, value] of additionalParams.entries()) {
      merged.append(key, value);
    }
  }

  return merged;
}
