'use client';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import Button from '@haaretz/s-button';
import React from 'react';
import s9 from 'style9';

import type { PaginatorProps } from '@haaretz/s-data-structure-types';

const c = s9.create({
  btn: {
    marginTop: space(5),
    gridColumnStart: 1,
    gridColumnEnd: -1,
    marginRight: 'auto',
    marginLeft: 'auto',

    ...merge(
      mq({
        from: 'l',
        value: {
          gridColumnStart: 3,
        },
      })
    ),
  },
});

export default function TatePaginator({
  children,
  isPending,
  displayMoreItems,
  hasMoreTeasers,
}: PaginatorProps) {
  return (
    <>
      {children}
      {hasMoreTeasers ? (
        <Button
          priority="secondary"
          onClick={displayMoreItems}
          styleExtend={[c.btn]}
          {...(isPending
            ? {
                state: 'busy',
                busyNotice: 'טוען...',
              }
            : { state: 'auto' })}
        >
          עוד כתבות
        </Button>
      ) : null}
    </>
  );
}
