import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import * as React from 'react';
import s9 from 'style9';

import type { InlineStyles, StyleExtend, WithAttrPropsAndAs } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    '--outerMarginWidth': space(3),
    '--outerGutterWidth': 0,
    '--marginaliaWidth': 0,
    '--innerGutterWidth': 0,
    // The length of the text column
    '--measure': 'minmax(0, 1fr)',
    // The vertical gap between block child elements
    '--rowSpacing': space(7),
    display: 'grid',
    rowGap: 'var(--rowSpacing)',
    gridTemplateColumns:
      '[bleed-start] var(--outerMarginWidth) ' +
      '[outerGutter-start] var(--outerGutterWidth) ' +
      '[marginalia-start] var(--marginaliaWidth) ' +
      '[innerGutter-start] var(--innerGutterWidth) ' +
      '[main-start] var(--measure) [main-end] ' +
      'var(--innerGutterWidth) [innerGutter-end] ' +
      'var(--marginaliaWidth) [marginalia-end] ' +
      'var(--outerGutterWidth) [outerGutter-end] ' +
      'var(--outerMarginWidth) [bleed-end]',
    ...merge(
      mq({
        from: 's',
        until: 'm',
        value: {
          '--innerGutterWidth': space(7),
          '--measure': space(120),
          '--outerGutterWidth': 'minmax(0, 1fr)',
          '--outerMarginWidth': space(8),
        },
      }),
      mq({
        from: 'm',
        until: 'l',
        value: {
          '--innerGutterWidth': space(7),
          '--measure': space(140),
          '--outerGutterWidth': `minmax(${space(7)},1fr)`,
          '--outerMarginWidth': space(8),
        },
      }),
      mq({
        from: 'l',
        until: 'xxl',
        value: {
          '--innerGutterWidth': space(7),
          '--marginaliaWidth': space(33),
          '--measure': space(125),
          '--outerGutterWidth': '1fr',
          '--outerMarginWidth': space(8),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          '--innerGutterWidth': space(7),
          '--marginaliaWidth': space(75),
          '--measure': space(150),
          '--outerGutterWidth': '1fr',
          '--outerMarginWidth': `minmax(0,${space(22)})`,
          '--rowSpacing': space(10),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          '--innerGutterWidth': space(7),
          '--marginaliaWidth': space(75),
          '--measure': space(180),
          '--outerGutterWidth': '1fr',
          '--outerMarginWidth': `minmax(0,${space(22)})`,
          '--rowSpacing': space(10),
        },
      })
    ),
  },
});

type AllowedElements = 'div' | 'footer' | 'header' | 'main' | 'section';

interface MagazineLayoutOwnProps<As extends AllowedElements> {
  /**
   * The HTML element (`string`) or React component
   * the `<LayoutContainer />` will be rendered as.
   */
  as?: As;
  /** The Children to be rendered inside `<MagazineLayout>` */
  children?: React.ReactNode;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyMagazineLayout(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <MagazineLayout
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <MagazineLayout styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

export const DEFAULT_ELEMENT = 'div';
type DefaultElement = typeof DEFAULT_ELEMENT;

export type MagazineLayoutProps<As extends AllowedElements = DefaultElement> = Omit<
  WithAttrPropsAndAs<MagazineLayoutOwnProps<As>, As>,
  'style'
>;

export default function MagazineLayout<As extends AllowedElements = DefaultElement>(
  props: MagazineLayoutProps<As>
) {
  const {
    as = DEFAULT_ELEMENT,
    children = null,
    inlineStyle,
    styleExtend = [],
    className,
    ...attrs
  } = props;
  const Element: AllowedElements = as;

  return (
    <Element
      className={`${s9(c.base, ...styleExtend)}${className ? ` ${className}` : ''}`}
      style={inlineStyle}
      {...attrs}
    >
      {children}
    </Element>
  );
}
