import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import HdcLogo from '@haaretz/s-hdc-logo';
import HtzLink from '@haaretz/s-htz-link';
import HtzLogo from '@haaretz/s-htz-logo';
import MarkerLogo from '@haaretz/s-marker-logo';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import s9 from 'style9';

import Wrapper from './Wrapper';

import type { MainLogoVariant } from '../../Masthead.types';
import type { StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  iconWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    marginInlineEnd: 'auto',
    marginInlineStart: 'auto',
    pointerEvents: 'auto',
  },
  defaultLogo: {
    fontSize: space(5),
    transition: 'all 0.2s ease-in-out',
    ...merge({
      ...mq({
        from: 'm',
        value: { display: 'inline', marginTop: space(0.3), fontSize: space(5.5) },
      }),
    }),
  },
  extendedIcon: {
    fontSize: space(8),
    ...merge({
      ...mq({
        from: 'm',
        value: { fontSize: space(12) },
      }),
    }),
  },

  dateAndTime: {
    display: 'none',
    ...merge({
      ...mq({ from: 'l', value: { display: 'inline', marginTop: space(0.3) } }),
    }),
  },
  searchPageLogo: {
    fontSize: space(7),
    ...merge(
      mq({
        from: 'l',
        value: {
          fontSize: space(8),
        },
      })
    ),
  },
  tmLogo: {
    fontSize: space(6),
    ...merge(
      mq({
        from: 'l',
        value: {
          fontSize: space(8),
        },
      })
    ),
  },
  tmExtendedLogo: {
    fontSize: space(8),
    ...merge(
      mq({
        from: 'l',
        value: {
          fontSize: space(11),
        },
      })
    ),
  },
  tmColorLogo: {
    color: color('tmBrand'),
  },
});

export const logoMapping = {
  default: fork({
    default: <HtzLogo styleExtend={[c.defaultLogo]} />,
    tm: <MarkerLogo styleExtend={[c.tmLogo, c.tmColorLogo]} />,
    hdc: <HdcLogo styleExtend={[c.defaultLogo]} />,
  }),
  podcast: fork({
    default: <HtzLogo styleExtend={[c.defaultLogo]} />,
    tm: <MarkerLogo styleExtend={[c.tmLogo, c.tmColorLogo]} />,
    hdc: <HdcLogo styleExtend={[c.defaultLogo]} />,
  }),
  liveBlog: fork({
    default: <HtzLogo styleExtend={[c.defaultLogo]} />,
    tm: <MarkerLogo styleExtend={[c.tmLogo, c.tmColorLogo]} />,
    hdc: <HdcLogo styleExtend={[c.defaultLogo]} />,
  }),
  magazine: fork({
    default: <HtzLogo styleExtend={[c.defaultLogo]} />,
    tm: <MarkerLogo styleExtend={[c.tmLogo]} />,
    hdc: <HdcLogo styleExtend={[c.defaultLogo]} />,
  }),
  quotes: fork({
    default: <HtzLogo styleExtend={[c.defaultLogo]} />,
    tm: <MarkerLogo styleExtend={[c.tmLogo]} />,
    hdc: <HdcLogo styleExtend={[c.defaultLogo]} />,
  }),
  extended: fork({
    default: <HtzLogo styleExtend={[c.defaultLogo, c.extendedIcon]} />,
    tm: <MarkerLogo styleExtend={[c.tmExtendedLogo, c.tmColorLogo]} />,
    hdc: <HdcLogo styleExtend={[c.defaultLogo]} />,
  }),
  searchPage: fork({
    default: <HtzLogo styleExtend={[c.defaultLogo, c.searchPageLogo]} />,
    tm: <MarkerLogo styleExtend={[c.tmLogo, c.tmColorLogo, c.searchPageLogo]} />,
    hdc: <HdcLogo styleExtend={[c.defaultLogo, c.searchPageLogo]} />,
  }),
};

type CommonProps = {
  variant: Exclude<MainLogoVariant, 'extended'>;
  styleExtend?: StyleExtend;
};

export default function Common({ variant, styleExtend }: CommonProps) {
  return (
    <Wrapper variant={variant} styleExtend={styleExtend}>
      <HtzLink className={s9(c.iconWrapper)} href="/">
        {logoMapping[variant]}
        <VisuallyHidden>
          {fork({
            default: 'הארץ – חזרה לעמוד הבית',
            tm: 'The Marker – חזרה לעמוד הבית',
            hdc: 'Haaretz - back to home page',
          })}
        </VisuallyHidden>
      </HtzLink>
    </Wrapper>
  );
}
