'use client';

import color from '@haaretz/l-color.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Dialog from '@haaretz/s-dialog';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import MobileHeader from '../MobileHeader';

import AudioReader from './AudioReader';
import ColorMode from './ColorMode';
import ControlsDelimeter from './ControlsDelimeter';
import ControlsWrapper from './ControlsWrapper';

import type { NavigationEntryFragment } from '@haaretz/s-fragments/NavigationEntry';

const c = s9.create({
  dialog: {
    color: color('bodyText'),
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundImage: `radial-gradient(circle at 57% 0, ${color('mastheadGradient')}, ${color(
      'neutral150'
    )} 94%)` as string,
    border: 'none',
    paddingInlineStart: space(6),
    paddingInlineEnd: space(6),
    paddingTop: space(6),
    display: 'flex',
    flexDirection: 'column',
    bottom: 0,
    transitionProperty: 'transform',
    transitionDuration: '0.5s',
    transitionTimingFunction: 'ease-in-out',
    transform: 'translateY(100%)',
  },
  dialogOpen: {
    transform: 'translateY(0)',
  },
  closeIcon: {
    textAlign: 'end',
    backgroundColor: 'transparent',
  },
  closeIconWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  linksWrapper: {
    marginTop: space(4),
    ...typesetter(1),
  },
  link: {
    display: 'block',
    textDecoration: 'underline',
    textDecorationColor: color('mastheadTextUnderline'),
    textDecorationThickness: space(0.25),
    textUnderlineOffset: space(2),
    marginTop: space(4),
    marginBottom: space(4),
    ...typesetter(1),
  },
});

export interface UserMenuProps {
  navUserMenuItems: NavigationEntryFragment['subEntries'];
  isOpen: boolean;
  focusOnFirstItem?: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}

export default function MobileUserMenu({
  navUserMenuItems,
  isOpen,
  onClick,
  focusOnFirstItem,
  children,
}: UserMenuProps) {
  const [isAnimating, setIsAnimating] = React.useState(false);
  const firstLinkRef = React.useRef<HTMLAnchorElement>(null);
  const biAction = useBi();

  React.useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
      if (firstLinkRef.current && focusOnFirstItem) {
        firstLinkRef.current.focus();
      }
    }
  }, [focusOnFirstItem, isOpen]);

  const handleTransitionEnd = () => {
    if (!isOpen || !isAnimating) {
      onClick?.();
    }
  };

  const keyboardEscape = (e: React.KeyboardEvent<HTMLDialogElement>) => {
    if (['Escape'].includes(e.key)) {
      e.stopPropagation();
      e.preventDefault();
      setIsAnimating(false);
    }
  };

  return (
    <Dialog
      styleExtend={[c.dialog, isAnimating && isOpen && c.dialogOpen]}
      isOpen={isOpen}
      onTransitionEnd={handleTransitionEnd}
      onKeyDown={keyboardEscape}
    >
      <div className={s9(c.closeIconWrapper)}>
        <button
          className={s9(c.closeIcon)}
          onClick={() => {
            setIsAnimating(false);
          }}
        >
          <Icon icon="close" variant="neutral" />
        </button>
      </div>
      <MobileHeader />
      <div className={s9(c.linksWrapper)}>
        {navUserMenuItems.map((item, id) => {
          const { href, title } = item;
          if (!href || !title) return null;
          return (
            <HtzLink
              key={href}
              href={href}
              className={s9(c.link)}
              ref={id === 0 ? firstLinkRef : null}
              onClick={() => {
                biAction({
                  action_id: 133,
                  feature: 'Account Ruler on Header',
                  feature_type: 'Personal',
                  campaign_name: title,
                });
              }}
            >
              {item.title}
            </HtzLink>
          );
        })}
      </div>
      {children}
      <ControlsWrapper>
        <ColorMode />
        <ControlsDelimeter />
        <AudioReader />
      </ControlsWrapper>
    </Dialog>
  );
}
