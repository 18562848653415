'use client';

import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { useRainbowAvailableStatus } from '../RainbowProvider';

import FactsInitializer from './FactsInitializer';
import useFactsFetcher from './useFactsFetcher';

import type { JsonValue } from '@haaretz/s-types';

export type FactsType = { [key: string]: JsonValue };
export type TFactsContext = [facts: FactsType, isPending: boolean];

interface FactsProviderProps {
  children: React.ReactNode;
}

const FactsContext = createContext<TFactsContext | null>(null);

const EMPTY_CONTEXT: TFactsContext = [{}, false];

export function FactsProvider({ children }: FactsProviderProps) {
  const [facts, setFacts] = useState<FactsType>({});
  const [isPending, setIsPending] = useState<boolean>(false);

  const factsFetcher = useFactsFetcher();

  const initializer = useCallback(async () => {
    setIsPending(true);
    const data = await factsFetcher();

    setFacts(data);
    setIsPending(false);
  }, [factsFetcher]);

  const ctx = useMemo<TFactsContext>(() => [facts, isPending], [facts, isPending]);

  return (
    <FactsContext.Provider value={ctx}>
      <FactsInitializer initializer={initializer} />
      {children}
    </FactsContext.Provider>
  );
}

export default function useFacts() {
  const rainbowAvailability = useRainbowAvailableStatus();
  const ctx = useContext(FactsContext);

  if (!ctx && rainbowAvailability === 'missing') {
    throw new Error('FactsContext in null. useFacts was probably called outside a provider.');
  }

  return ctx || EMPTY_CONTEXT;
}
