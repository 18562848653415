'use client';

import usePlatform from '@haaretz/s-atoms/platform';
import { htzHostnames } from '@haaretz/s-consts';
import breakUrl from '@haaretz/s-url-utils/breakUrl';
import isNextLink from '@haaretz/s-url-utils/isNextLink';
import Link from 'next/link';
import * as React from 'react';

import type { LinkProps as NextLinkProps } from 'next/link';

export type Url = NextLinkProps['href'];

/* eslint-disable @typescript-eslint/indent */
/**
 * Extends Next.js's `<Link />` component and takes the modern subset of its
 * props.
 * See the {@link https://nextjs.org/docs/api-reference/next/link | Next.js docs}
 * for more details.
 *
 * @public
 */
export type HtzLinkProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof NextLinkProps
> &
  Omit<
    NextLinkProps,
    // Disallow old NextLink props
    | 'passHref'
    | 'legacyBehavior'
    // NextLink doesn't currently have `children` in its exported props,
    // but this is here to future-proof it in case it is added later.
    | 'children'
    // Conflicts with polymorphic conmonent prop. Redirected from 'hrefAs'
    | 'as'
  > & {
    /** The Children to be rendered inside `<HtzLink>` */
    children?: React.ReactNode;
    /**
     * When different than `href`, the path that will be rendered in the browser
     * URL bar. Used for dynamic routes. Otherwise used to decorate how `href` is
     * displayed in the browser's URL bar.
     */
    hrefAs?: NextLinkProps['as'];
  };
/* eslint-enable @typescript-eslint/indent */

const HtzLink = React.forwardRef<HTMLAnchorElement, HtzLinkProps>(function HtzLinkComponent(
  { children = null, href, hrefAs, locale, prefetch = false, replace, scroll, shallow, ...attrs },
  forwardedRef
) {
  const platform = usePlatform();
  const overrideAttrs: React.ComponentProps<'a'> & { 'data-google-interstitial'?: false } = {};

  if (typeof href !== 'string' && !href?.href) {
    console.warn(
      'The "href" prop you passed <HtzLink /> is a "URL" object without an "href" field:',
      href,
      '\nOpting out of rendering the element'
    );
    // eslint-disable-next-line no-console
    console.trace();

    return null;
  }

  if (isNextLink(href, platform)) {
    return (
      <Link
        as={hrefAs}
        href={href}
        // href={`${href}${href.toString().includes('?') ? '&' : '?'}forcecanary`}
        locale={locale}
        prefetch={prefetch}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        legacyBehavior={false}
        referrerPolicy={hrefAs ? 'unsafe-url' : undefined}
        {...attrs}
        {...overrideAttrs}
        ref={forwardedRef}
      >
        {children}
      </Link>
    );
  }

  const url = typeof href === 'string' ? href : href?.href || undefined;

  const { hostname: urlHostname } = breakUrl(url ?? '');

  const isHtzLink = htzHostnames.some(host => urlHostname.includes(host));

  if (isHtzLink) {
    overrideAttrs['data-google-interstitial'] = false;
  }

  return (
    <a href={url} {...attrs} {...overrideAttrs} ref={forwardedRef}>
      {children}
    </a>
  );
});

export default HtzLink;
