import RainbowProvider from '@haaretz/s-rainbow/RainbowDataProvider';
import { BiDataContextProvider } from '@haaretz/s-use-bi';
import React from 'react';

import AtomProviders from '../AtomProviders/AtomProviders';

import ClientGlobalProviders from './ClientGlobalProviders';
import ClientState from './ClientState';

import type { AppGlobalType } from '@haaretz/s-data-structure-types';
import type { CommonPageLayoutFragment } from '@haaretz/s-fragments/CommonPageLayout';

interface GlobalProvidersProps
  extends Pick<
    CommonPageLayoutFragment,
    'defaultRainbowTools' | 'useDefaultOnlyForAnonymous' | 'defaultRainbowToolsTimeout'
  > {
  children: React.ReactNode;
  initialGlobalStore: AppGlobalType;
}

export default function GlobalProviders({
  children,
  useDefaultOnlyForAnonymous,
  defaultRainbowTools,
  defaultRainbowToolsTimeout,
  initialGlobalStore,
}: Readonly<GlobalProvidersProps>) {
  return (
    <AtomProviders initialGlobalStore={initialGlobalStore}>
      <ClientState />
      <ClientGlobalProviders>
        <BiDataContextProvider>
          <RainbowProvider
            useDefaultOnlyForAnonymous={useDefaultOnlyForAnonymous}
            defaultRainbowTools={defaultRainbowTools}
            defaultRainbowToolsTimeout={defaultRainbowToolsTimeout}
          >
            {children}
          </RainbowProvider>
        </BiDataContextProvider>
      </ClientGlobalProviders>
    </AtomProviders>
  );
}
