'use client';

import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import Button from '@haaretz/s-button';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import React from 'react';
import s9 from 'style9';

import type { ErrorPageProps } from './ErrorPageTemplate';

const c = s9.create({
  btn: {
    marginBottom: 0,

    ...merge(
      mq({
        from: 'l',
        value: {
          marginBottom: 0,
        },
      })
    ),
  },
  btnNotFoundHtz: {
    marginBottom: space(8),

    ...merge(
      mq({
        from: 'l',
        value: {
          marginBottom: space(13),
        },
      })
    ),
  },
});

type ErrorPageButtonProps = {
  variant?: ErrorPageProps['variant'];
};

export default function BackToHomeButton({ variant }: ErrorPageButtonProps) {
  const biAction = useBi();

  return (
    <Button
      styleExtend={[
        c.btn,
        fork({ default: null, htz: variant === 'notFound' && c.btnNotFoundHtz }),
      ]}
      priority="secondary"
      as={HtzLink}
      href="/"
      onClick={() =>
        biAction({
          page_type: 'Error',
          action_id: 80,
        })
      }
    >
      {fork({ default: 'חזרה לעמוד הבית' })}
    </Button>
  );
}
