'use client';
import config from '@haaretz/l-config';
import useUser from '@haaretz/s-atoms/user';

import useClientQuery from '../useClientQuery';

import type { ListFragment } from '@haaretz/s-fragments/List';
import type { UseQueryOptions } from '@tanstack/react-query';

interface PersonalListVariables<TData = ListFragment, TError = Error> {
  variables:
    | {
        readArticleId?: never;
        viewedArticleIds?: string;
      }
    | {
        readArticleId?: string;
        viewedArticleIds?: never;
      };
  clientOptions?: UseQueryOptions<TData, TError, TData>;
  inView?: boolean;
}

/**
 * Get personal list data
 *
 * @public
 */
export default function useUpdateReadingHistory<
  TData extends object = ListFragment,
  TError = Error,
>({ inView, variables, clientOptions }: PersonalListVariables<TData, TError>) {
  const user = useUser('cookieValue');

  const url = `${config.get('personalization')}/update-reading-history`;

  return useClientQuery<TData, typeof variables, TError>({
    url,
    cacheKey: 'updateReadingHistory',
    variables,
    clientOptions: {
      ...clientOptions,
      enabled:
        (clientOptions?.enabled ?? true) &&
        user.isLoggedIn &&
        inView &&
        !!(variables.viewedArticleIds || variables.readArticleId),
      cacheTime: 0,
    },
  });
}
